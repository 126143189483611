<template>
  <div class="policy">
    <div class="container flex justify-center content-center direction-column h-100">
      <PageTitle :title="'Polityka prywatności'"/>
    </div>
  </div>
  <div class="section">
    <div class="container w-thin">
      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import store from '../store';
import PageTitle from '../components/PageTitle';

export default {
  name: 'Contact',
  components: { PageTitle },
  setup() {
    const content = ref('');

    onMounted(async () => {
      const pageContent = await store.dispatch('getPageContent', 3);
      content.value = pageContent.description;
      console.log(content.value);
    });

    onMounted(() => {
      document.title = 'Polityka prywatności - adamkanigowski.pl';
    });

    return { content };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/vars';

.policy {
  background: url('../assets/contact.jpg') no-repeat;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: $padding-4;

  @media #{$lg-min}  {
    background-position-y: -200px;
  }

  &__img {
    fill: $background-color;
    width: auto;
    height: 6rem;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: .05;
    z-index: 0;
    transform: translate(-50%, -50%);

    @media #{$md-min} {
      height: 10rem;
    }
  }
}
</style>
